<template>

  <div>
    <!-- @refetch-data="refetchData" -->

    <ruta-add-new
      :is-add-new-ruta-sidebar-active.sync="isAddNewRutaSidebarActive"
      :idruta="new Date().getTime()"
    />
    <!-- :clientes-list="clientes.map(i => i.id)" -->

    <!-- </b-card> -->

    <!-- Table Container Card -->
    <!-- <b-card
      no-body
      class="mb-10"
    > -->
    <b-tabs>
      <!-- Mapa -->
      <b-tab
        title="Mapa"
      >
        <h5>Mapa</h5>
      </b-tab>

      <!-- Picking -->
      <b-tab
        active
        title="Picking"
      >

        <!-- Content -->
        <b-card-actions
          title="Picking"
          no-body
          class="mb-0"
          action-collapse
        >

          <!-- Filters -->
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label>Zona</label>
                <v-select
                  v-model="zonaFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="zonaOptions"
                  class="w-100"
                />
              </b-col>

              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label>Status</label>
                <v-select
                  v-model="statusFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  class="w-100"
                />
              </b-col>

              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label>Ruta</label>
                <v-select
                  v-model="rutaFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="rutaOptions"
                  class="w-100"
                >
                  <template #option="{ label, value }">
                    <vehiculo-icon
                      :color="rutaColorIcon(value)"
                      size="24"
                    />
                    <span class="ml-1"> {{ label }}</span>
                  </template>

                </v-select>
              </b-col>

              <!-- Calcular Ruta -->
              <!-- <b-col
            cols="6"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="tratarPreruta"
            >
              Pre-Calcular Ruta
            </b-button>
          </b-col> -->
            </b-row>
          </b-card-body>

          <!-- Info -->
          <span class="text-info ml-3">{{ itemsMostrarOrden.totalBultos }} {{ itemsMostrarOrden.totalBultos !== 1 ? 'Bultos' : 'Bulto' }} en {{ itemsMostrarOrden.total }} {{ itemsMostrarOrden.total !== 1 ? 'Pedidos' : 'Pedido' }}</span>

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="10"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries of {{ itemsMostrarOrden.total }}</label>
              </b-col>

              <b-col
                cols="2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon"
                  @click="exportToExcel"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <!-- <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  /> -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon mr-1"
                    @click="isAddNewRutaSidebarActive = true"
                  >
                    Add New Ruta
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon mr-1"
                    @click="preCalculaRutas"
                  >
                    Pre-Calcular Ruta
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="itemsMostrarOrden.pedidos"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            table-variant="none"
            :sort-desc.sync="isSortDirDesc"
          >

            <!-- Column: select -->
            <template #cell(select)="data">
              <b-form-checkbox
                v-model="pedidosSelected"
                :value="data.item.id"
              />
            </template>

            <!-- Column: Pedido -->
            <template #cell(pedido)="data">
              <b-media vertical-align="center">
                <!-- <p
                  class="font-weight-bold d-block text-nowrap"
                  @click="editPedido(data.item)"
                >
                  {{ data.item.id }}
                </p> -->
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  @click="editPedido(data.item)"
                >
                  {{ data.item.id }}
                </b-link>
                <small class="text-muted">{{ data.item.fechaIso }}</small>
              </b-media>
            </template>

            <!-- Column: Cliente -->
            <template #cell(client)="data">
              <b-media vertical-align="center">
                <b-link
                  :to="{ name: 'apps-users-view', params: { id: data.item.cliente_id } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.cliente_id }}
                </b-link>
                <small class="text-muted">{{ data.item.cliente_nombre }}</small>
              </b-media>
            </template>

            <!-- Column: zona -->
            <template #cell(cliente_ruta)="data">
              <div class="text-nowrap">
                <span class="text-right font-weight-bolder">{{ data.item.cliente_ruta }}</span>
              </div>
            </template>

            <!-- Column: ruta -->
            <template #cell(ruta)="data">
              <div
                class="text-nowrap"
                @click="pickingVehiculo(data.item)"
              >
                <vehiculo-icon
                  :color="rutaColorIcon(data.item.ruta)"
                  size="36"
                />
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-button
                v-if="data.item.status && !data.item.is_picking_completed"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-warning"
                class="btn-icon"
                size="sm"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="8"
                />
              </b-button>
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status).color}`"
                class="text-capitalize"
              >
                {{ resolveUserStatusVariant(data.item.status).txt }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="data.item.status ? 'flat-success' : 'flat-secondary'"
                  class="btn-icon"
                  size="sm"
                  :disabled="data.item.is_picking_completed"
                  @click="picking(data.item)"
                >
                  <feather-icon
                    :id="`order-row-${data.item.id}-cart-icon`"
                    icon="ShoppingCartIcon"
                    class="cursor-pointer"
                    :color="data.item.status > 1 ? '#ffebeb' : ''"
                    size="16"
                  />
                </b-button>
                <!-- <b-tooltip
                  :title="data.item.status > 1 ? '' : 'Picking'"
                  class="cursor-pointer"
                  :target="`order-row-${data.item.id}-cart-icon`"
                /> -->
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details</span>
                  </b-dropdown-item>

                  <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <!-- <b-dropdown-item>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </div>
            </template>

          </b-table>

          <!-- Paginación -->
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="itemsMostrarOrden.total"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>

          <!-- Opciones de Grupo -->
          <div
            v-if="pedidosSelected.length || true"
            class="mx-2 mb-2"
          >
            <hr>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="selectPedidosAll(true)"
            >
              Seleccionar todo
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled="!pedidosSelected.length"
              @click="selectPedidosAll(false)"
            >
              De-Seleccionar todo
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled="!pedidosSelected.length"
              @click="pickingToRutas"
            >
              Asignar Ruta
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled="!pedidosSelected.length"
              @click="pickingToRutasOff"
            >
              Des-Asignar Ruta
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              class="btn-icon mr-1"
              @click="test"
            >
              Test
            </b-button> -->
          </div>
        </b-card-actions>
      </b-tab>

      <!-- Rutas -->
      <b-tab
        title="Rutas"
      >
        <b-card-actions
          title="Rutas"
          no-body
          class="mb-0"
          action-collapse
        >
          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="10"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries of {{ itemsMostrarOrden.total }}</label>
              </b-col>

              <b-col
                cols="2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon"
                  @click="exportToExcel"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="calculaRutas"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button> -->
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <!-- <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  /> -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon mr-1"
                    @click="isAddNewRutaSidebarActive = true"
                  >
                    Add New Ruta
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    class="btn-icon"
                    :disabled="!rutasSelectedConPedidos.length"
                    @click="calculaRutas"
                  >
                    Optimizar Rutas
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="rutasList"
            class="position-relative"
            :items="rutas"
            responsive
            :fields="rutasTableColumns"
            primary-key="id"
            :sort-by.sync="sortByRuta"
            show-empty
            empty-text="No matching records found"
            table-variant="none"
            :sort-desc.sync="isSortDirDescRuta"
          >

            <!-- Column: select -->
            <template #cell(select)="data">
              <b-form-checkbox
                v-model="rutasSelected"
                :value="data.item.id"
              />
            </template>

            <template #cell(id)="data">
              <div
                class="text-nowrap"
              >
                {{ data.item.id }}
                <vehiculo-icon
                  :color="rutaColorIcon(data.item.id)"
                  size="36"
                  class="ml-1"
                />
              </div>
            </template>

            <!-- Column: Pedidos -->
            <template #cell(pedidos)="data">
              <b-badge
                pill
                :variant="`light-${resolveRutaPedidos(data.item.id).color}`"
                class="text-capitalize"
              >
                {{ resolveRutaPedidos(data.item.id).value }}
              </b-badge>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveRutaStatusVariant(data.item.status).color}`"
                class="text-capitalize"
              >
                {{ resolveRutaStatusVariant(data.item.status).txt }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-button
                  v-if="data.item.calculated"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-success"
                  :href="data.item.calculated.link"
                  target="_blank"
                  class="btn-icon"
                >
                  <feather-icon icon="MapIcon" />
                </b-button>
                <!-- <feather-icon
                  v-if="data.item.calculated"
                  icon="ShoppingCartIcon"
                  class="cursor-pointer"
                  color="red"
                  size="16"
                  :href="data.item.calculated.link"
                  target="_blank"
                /> -->
                <!-- <b-tooltip
                  :title="data.item.status > 1 ? '' : 'Picking'"
                  class="cursor-pointer"
                  :target="`order-row-${data.item.id}-cart-icon`"
                /> -->
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details</span>
                  </b-dropdown-item>

                  <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <!-- <b-dropdown-item>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </div>
            </template>

          </b-table>

          <!-- Paginación -->
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="itemsMostrarOrden.total"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>

          <!-- Opciones de Grupo -->
          <div
            v-if="pedidosSelected.length || true"
            class="mx-2 mb-2"
          >
            <hr>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="selectRutasAll(true)"
            >
              Seleccionar todo
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled="!rutasSelected.length"
              @click="selectRutasAll(false)"
            >
              De-Seleccionar todo
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled="!rutasSelected.length"
              @click="imprimeEtiquetas"
            >
              Etiquetas
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-1"
              :disabled="!rutasSelected.length"
              @click="rutasDelete"
            >
              Eliminar
            </b-button> -->
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              :disabled="!pedidosSelected.length"
              @click="pickingToRutasOff"
            >
              Des-Asignar Ruta
            </b-button> -->
          </div>
        </b-card-actions>
      </b-tab>
    </b-tabs>
    <!-- </b-card> -->

    <!-- Opciones de Grupo -->
    <!-- <b-card
      v-if="pedidosSelected.length"
      no-body
      class="mb-0"
    >
      <b-card-header class="pb-50">
        <h5>
          Opciones de Grupo
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon mr-1"
                @click="isAddNewRutaSidebarActive = true"
              >
                Add New Ruta
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-1"
                @click="tratarPreruta"
              >
                Pre-Calcular Ruta
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon"
              >
                Optimizar Rutas
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card> -->

    <pedido-edit
      :pedido="pedidoSelect"
      :is-edit-pedido.sync="isEditPedido"
    />

    <pedido-picking
      :pedido="pedidoSelect"
      :is-picking-pedido.sync="isPickingPedido"
    />

    <picking-vehiculo
      :pedido="pedidoSelect"
      :rutas="rutas"
      :is-picking-vehiculo.sync="isPickingVehiculo"
    />

    <picking-pedidos-to-rutas
      :pedidos="pedidosSelected"
      :is-picking-pedidos-to-rutas.sync="isPickingPedidosToRutas"
      @cierraVentana="isPickingPedidosToRutas = false"
    />

    <!-- isEtiquetasSelectionInit -->
    <rutas-to-etiquetas
      :is-etiquetas-selection-init.sync="isEtiquetasSelectionInit"
      @selectedEtiquetasInit="imprimeEtiquetasEnviar"
    />

    <mapa-picking
      :markers="itemsToMap"
      :path="overviewPath"
      :pre="true"
      :markers-select-by-picking="selectedToMarkers"
      @newRutaCalculed="newRutaCalculed"
      @selectedByMarker="selectedByMarker"
    />
  </div>
</template>

<script>
// import preRutaFacke from '@/@fake-db/data/pre_ruta.json'
// import rutasOptimizadasFack from '@/@fake-db/data/rutas_optimizadas.json'
// import rutasSelectOptimizarFack from '@/@fake-db/data/rutas_select_optimizar.json'
import {
  BRow, BCol, BButton, BTable, BCardBody, // BCard, BCardHeader,
  BDropdown, BDropdownItem, BPagination, BMedia, BLink, BBadge, BFormCheckbox, // BTooltip,
  BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { paginateArray, sortCompare } from '@/libs/filter-data'
import Ripple from 'vue-ripple-directive'
// import ClienteAddNew from '@/views/bimbo/components/ClienteAddNew.vue'
// import PedidoAddNew from '@/views/bimbo/components/PedidoAddNew.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import PedidoEdit from '@/views/bimbo/components/PedidoEdit.vue'
import PedidoPicking from '@/views/bimbo/components/PedidoPicking.vue'
import { directions } from '@/libs/google-directions'
import MapaPicking from '@/views/bimbo/components/MapaPicking.vue'
import VehiculoIcon from '@/assets/images/icons/maps/VehiculoIcon.vue'
import { testPdf } from '@/libs/printer-pdf'
import PickingVehiculo from '../../components/PickingVehiculo.vue'
import RutaAddNew from '../../components/RutaAddNew.vue'
import PickingPedidosToRutas from '../../components/PickingPedidosToRutas.vue'
import RutasToEtiquetas from '../../components/RutasToEtiquetas.vue'
// import { MapElementFactory } from 'vue2-google-maps'
// import { export_json_to_excel } from '@/libs/Export2Excel'

const DIRALMACEN = 'Carrer de Pere IV, 520, Barcelona'

export default {
  components: {
    // ClienteAddNew,
    // PedidoAddNew,
    BCardActions,
    PedidoEdit,
    PedidoPicking,

    // BCard,
    // BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BFormCheckbox,
    BTabs,
    BTab,
    // BTooltip,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    MapaPicking,
    VehiculoIcon,
    PickingVehiculo,
    PickingPedidosToRutas,
    RutasToEtiquetas,
    RutaAddNew,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isEtiquetasSelectionInit: false, // Modal de selección de comienzo de impresión etiquetas
      isPickingPedido: false,
      isEditPedido: false,
      isPickingVehiculo: false,
      isPickingPedidosToRutas: false,
      pedidoSelect: {},
      isAddNewClienteSidebarActive: false,
      isAddNewRutaSidebarActive: false,
      zonaOptions: [
        { label: 'Zona 0', value: '0' },
        { label: 'Zona 1', value: '1' },
        { label: 'Zona 2', value: '2' },
      ],
      tableColumns: [
        { key: 'select', label: '', sortable: false },
        { key: 'orden', label: '#', sortable: false },
        { key: 'pedido', label: 'Pedido', sortable: false },
        { key: 'client', label: 'Cliente', sortable: false },
        { key: 'cliente_ruta', label: 'Z', sortable: false },
        { key: 'bultos', label: 'B', sortable: false },
        { key: 'ruta', label: 'Ruta', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'actions' },
      ],
      zonaFilter: null,
      statusFilter: { label: 'Pending', value: 0 },
      // 0 => Pending, 1 => Picked, 3 => Routed, 7 => Dispatched, 15 Delivered
      statusOptions: [
        { label: 'Pending', value: 0 },
        { label: 'Picked', value: 1 },
        { label: 'Routed', value: 3 },
        { label: 'Dispatched', value: 7 },
        { label: 'Delivered', value: 15 },
      ],
      rutaFilter: null,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      // Export Excell
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'csv',
      // Picking Control Pre-Ruta
      pedidosPreOrdenados: [],
      pedidosOrdenAnterior: [],
      overviewPath: [],
      pedidosSelected: [],
      calculandoRuta: false,
      // Picking Control Optimizar-Ruta
      rutasSelecOptimizar: [],
      rutasOptimizadas: [],
      // Rutas
      sortByRuta: 'id',
      isSortDirDescRuta: true,
      rutasSelected: [],
      rutasTableColumns: [
        { key: 'select', label: '', sortable: false },
        { key: 'id', label: 'id', sortable: true },
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'vehiculo_tipo', label: 'Vehiculo', sortable: true },
        { key: 'pedidos', label: 'p', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    productos() {
      return this.$store.state.productos.productos
    },
    rutasImprimirSeleccionadas() {
      const rutas = this.rutasSelectedConPedidos
      const result = []
      for (let index = 0; index < rutas.length; index += 1) {
        const ruta = rutas[index]
        const pedidos = this.rutasGroupPedidos[ruta]
        pedidos.forEach(p => {
          const pedido = this.items.filter(a => a.id === p)[0]
          let nbultos = 0 // Número de bultos calculado en función del QUT
          // pedido.calcBorrar = []
          pedido.productos.forEach(pr => {
            const { ref, uds } = pr
            const article = this.productos.filter(f => f.id === ref)[0]
            if (!article) {
              // customAlert('Error:')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error: Producto no encontrado.',
                  icon: 'BellIcon',
                  text: `No se encuentra el producto ${ref} incluido en el pedido ${p}`,
                  variant: 'warning',
                },
              })
            } else {
              const qut = article ? article.quantityPerUnitTransport : 1
              const calc = Math.ceil(uds / qut)
              // console.log(`Math.ceil(${uds} / ${qut}): ${calc}`)
              // pedido.calcBorrar.push(`ref ${ref} = ${qut} qut => Math.ceil(${uds} / ${qut}): ${calc}`)
              nbultos += calc
            }
          })
          pedido.numEtiquetas = nbultos
          result.push(pedido)
        })
      }
      return result
    },
    rutasOptimizadasLength() {
      return this.rutasOptimizadas.length
    },
    selectedToMarkers() {
      const result = []
      if (this.pedidosSelected.length) {
        this.pedidosSelected.forEach(p => {
          const clienteId = this.items.filter(i => i.id === p).map(f => f.cliente_id)[0]
          result.push(clienteId)
        })
      }
      return result
    },
    rutaOptions() {
      if (!this.rutas.length) return []
      return this.rutas.map(r => ({ label: `${r.vehiculo_tipo} - ${r.nombre}`, value: r.id }))
    },
    rutasIndex() {
      const rutasAll = this.rutas
      const result = rutasAll.map((r, i) => ({
        index: i,
        id: r.id,
      }))
      return result
    },
    rutasGroupPedidos() {
      // Grupo de pedidos por cada ruta
      // const result = this.rutas.map(r => ({
      //   [r.id]: [],
      // }))
      const result = {}
      this.rutas.forEach(r => {
        result[r.id] = []
      })
      const pedidos = this.pedidosRutas
      if (pedidos.length) {
        for (let index = 0; index < this.rutas.length; index += 1) {
          const ruta = this.rutas[index]
          const pedidosR = pedidos.filter(p => p.ruta === ruta.id)
          for (let i = 0; i < pedidosR.length; i += 1) {
            const pedido = pedidosR[i]
            result[ruta.id].push(pedido.id)
          }
        }
      }
      return result
    },
    rutasSelectedConPedidos() {
      const result = []
      this.rutasSelected.forEach(ruta => {
        if (this.rutasGroupPedidos[ruta].length) {
          result.push(ruta)
        }
      })
      return result
    },
    pedidosRutas() {
      let result = []
      const pedidos = this.items.filter(p => p.ruta)
      if (pedidos.length) result = pedidos.map(p => ({ id: p.id, ruta: p.ruta }))
      return result
    },
    rutas() {
      return this.$store.state.rutas.rutas
    },
    vehiculos() {
      return this.$store.state.vehiculos.vehiculos
    },
    clientes() {
      return this.$store.state.clientes.clientes
    },
    items() {
      return this.$store.state.pedidos.pedidos
    },
    // itemsSanitized() {
    //   const pedidos = this.items
    //   pedidos.map(pedido => {
    //     const item = pedido
    //     item.cliente_dir_literal = this.sanitizeDir(pedido.cliente_dir_literal)
    //     return item
    //   })
    //   return pedidos
    // },
    itemsFilterPedidos() {
      const {
        zonaFilter,
        statusFilter,
        rutaFilter,
        searchQuery,
        sortBy,
        isSortDirDesc,
      } = this.$data
      let pedidos = this.items
      const queryLowered = searchQuery.toLowerCase()
      pedidos = pedidos.filter(
        pedido =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (pedido.id.toLowerCase().includes(queryLowered) || pedido.cliente_id.toLowerCase().includes(queryLowered) || pedido.cliente_nombre.toLowerCase().includes(queryLowered) || pedido.cliente_dir_literal.toLowerCase().includes(queryLowered)) && pedido.cliente_ruta.toString() === (zonaFilter?.value || pedido.cliente_ruta.toString()) && pedido.status.toString() === (statusFilter?.value.toString() || pedido.status.toString()),
      )
      /* eslint-enable  */
      if (rutaFilter?.value) {
        pedidos = pedidos.filter(
          pedido => (pedido.ruta && pedido.ruta.toString() === (rutaFilter?.value.toString() || pedido.ruta.toString())),
        )
      }

      const sortedData = pedidos.sort(sortCompare(sortBy))
      if (isSortDirDesc) sortedData.reverse()
      return pedidos
    },
    itemsFiltereds() {
      const {
        perPage,
        currentPage,
      } = this.$data
      const pedidos = this.itemsFilterPedidos
      const reducer = (accumulator, currentValue) => accumulator + currentValue
      if (!pedidos.length) {
        return {
          pedidos: [],
          total: 0,
          totalBultos: 0,
        }
      }
      const pedidosResult = pedidos.map((pedido, i) => {
        const ped = pedido
        ped.orden = i
        ped.bultos = ped.productos.map(p => p.uds).reduce(reducer)
        ped.fechaIso = this.fechaHoraCortas(ped.fecha)
        return ped
      })
      return {
        pedidos: paginateArray(pedidosResult, perPage, currentPage),
        total: pedidosResult.length,
        totalBultos: pedidosResult.map(p => p.bultos).reduce(reducer),
      }
    },
    itemsFilteredsOld() {
      const {
        zonaFilter,
        statusFilter,
        perPage,
        currentPage,
        searchQuery,
        sortBy,
        isSortDirDesc,
      } = this.$data
      let pedidos = this.items
      const queryLowered = searchQuery.toLowerCase()
      pedidos = pedidos.filter(
        pedido =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (pedido.id.toLowerCase().includes(queryLowered) || pedido.cliente_id.toLowerCase().includes(queryLowered) || pedido.cliente_nombre.toLowerCase().includes(queryLowered) || pedido.cliente_dir_literal.toLowerCase().includes(queryLowered)) && pedido.cliente_ruta.toString() === (zonaFilter?.value || pedido.cliente_ruta.toString()) && pedido.status.toString() === (statusFilter?.value.toString() || pedido.status.toString()),
      )
      /* eslint-enable  */

      const sortedData = pedidos.sort(sortCompare(sortBy))
      if (isSortDirDesc) sortedData.reverse()
      const reducer = (accumulator, currentValue) => accumulator + currentValue
      if (!pedidos.length) {
        return {
          pedidos: [],
          total: 0,
          totalBultos: 0,
        }
      }
      const pedidosResult = pedidos.map((pedido, i) => {
        const ped = pedido
        ped.orden = i
        ped.bultos = ped.productos.map(p => p.uds).reduce(reducer)
        ped.fechaIso = this.fechaHoraCortas(ped.fecha)
        return ped
      })
      return {
        pedidos: paginateArray(pedidosResult, perPage, currentPage),
        total: pedidosResult.length,
        totalBultos: pedidosResult.map(p => p.bultos).reduce(reducer),
      }
    },
    itemsMostrarOrden() {
      // const {
      // perPage,
      // currentPage,
      // sortBy,
      // isSortDirDesc,
      // } = this.$data
      if (this.pedidosPreOrdenados.length) {
        const pedidos = this.pedidosPreOrdenados
        // const sortedData = pedidos // pedidos.sort(sortCompare(sortBy))
        // if (isSortDirDesc) sortedData.reverse()
        return {
          pedidos, // paginateArray(sortedData, perPage, currentPage),
          total: this.pedidosPreOrdenados.length,
          totalBultos: this.itemsFiltereds.totalBultos,
        }
      }
      return this.itemsFiltereds
    },
    itemsToMap() {
      const data = this.itemsMostrarOrden?.pedidos
      if (!data.length) return []
      const result = data.map(p => ({
        id: p.cliente_id,
        orden: p.orden,
        lat: p.cliente_lat,
        lng: p.cliente_lng,
        nombre: p.cliente_nombre,
        ruta: p.cliente_ruta,
      }))

      return result
    },
  },

  watch: {
    rutasOptimizadasLength() {
      console.log('avisar rutas optimizadas')
    },
  },

  created() {
    this.fetchPedidos()
    this.fetchClientes()
    this.fetchVehiculos()
    this.fetchRutas()
    this.fetchProductos()
  },

  mounted() {
    // console.log('mounted', this.rutasOptimizadasLength)
    // this.fackDataBorrar()
  },

  methods: {
    // sanitizeDir(dir) {

    // },
    fetchProductos() {
      this.$store.dispatch('productos/getProductos')
    },
    imprimeEtiquetas() {
      // Abre diálogo de comienzo
      this.isEtiquetasSelectionInit = true
    },
    imprimeEtiquetasEnviar(init) {
      console.log('imprimiendo', init)
      // const rutas = this.rutasSelectedConPedidos
      this.isEtiquetasSelectionInit = false
      const etiquetasAimprimir = this.rutasImprimirSeleccionadas
      if (!etiquetasAimprimir || !etiquetasAimprimir.length) return
      testPdf(etiquetasAimprimir, init - 1)
      // console.log(etiquetasAimprimir)
    },
    fackDataBorrar() {
      // this.calculandoRuta = false
      setTimeout(() => {
        // console.log('ejecutar')
        // this.calculandoRuta = true
        // this.rutasOptimizadas = rutasOptimizadasFack
        // this.rutasSelecOptimizar = rutasSelectOptimizarFack
      }, 5000)
    },
    rutaColorIcon(ruta) {
      let result = '#c4c4c4'
      const item = this.rutas.filter(r => r.id === ruta)[0]
      if (item) {
        result = item.color
      }
      return result
    },
    picking(item) {
      if (!item || item.status > 1 || item.is_picking_completed) return
      this.pedidoSelect = item
      this.isPickingPedido = true
    },
    selectedByMarker(clienteId) {
      const pedido = this.items.filter(i => i.cliente_id === clienteId)[0]
      if (!pedido) return
      const index = this.pedidosSelected.indexOf(pedido.id)
      if (index > -1) this.pedidosSelected.splice(index, 1)
      else this.pedidosSelected.push(pedido.id)
    },
    selectPedidosAll(valor) {
      this.pedidosSelected = []
      if (valor) {
        this.itemsMostrarOrden.pedidos.forEach(i => {
          this.pedidosSelected.push(i.id)
        })
      }
    },
    selectRutasAll(valor) {
      this.rutasSelected = []
      if (valor) {
        this.rutas.forEach(i => {
          this.rutasSelected.push(i.id)
        })
      }
    },
    rutasDelete() {
      // eliminar rutas seleccionadas
      console.log('eliminar: ', this.rutasSelected)
    },
    pickingVehiculo(item) {
      if (!item || item.status > 1) return
      // this.pedidoSelect = item
      // this.isPickingVehiculo = true
      this.pedidosSelected = [item.id]
      this.pickingToRutas()
    },
    pickingToRutas() {
      this.isPickingPedidosToRutas = true
    },
    pickingToRutasOff() {
      // Des-Asignar Rutas
      for (let index = 0; index < this.pedidosSelected.length; index += 1) {
        const pedido = this.pedidosSelected[index]
        const pedidoObj = this.items.filter(i => i.id === pedido)[0]
        const payload = {
          pedido,
          ruta: null,
        }
        if (pedidoObj && pedidoObj.ruta) this.$store.dispatch('rutas/peditoToRuta', payload)
      }
    },
    editPedido(p) {
      this.pedidoSelect = p
      this.isEditPedido = true
    },
    fetchPedidos() {
      this.$store.dispatch('pedidos/getPedidos')
    },
    fetchClientes() {
      this.$store.dispatch('clientes/getClientes')
    },
    fetchVehiculos() {
      this.$store.dispatch('vehiculos/getVehiculos')
    },
    fetchRutas() {
      this.$store.dispatch('rutas/getRutas')
    },

    sumaBultos(accumulator, currentValue) {
      return accumulator + currentValue
    },

    fechaHoraCortas(f) {
      const fecha = new Date(f) || new Date()
      return new Intl.DateTimeFormat('es-ES', { dateStyle: 'short', timeStyle: 'short' }).format(fecha)
    },

    resolveUserStatusVariant(status) {
      // 0 => Pending, 1 => Picked, 3 => Routed, 7 => Dispatched, 15 Delivered
      const result = {
        txt: 'Unknown',
        color: 'danger',
      }
      switch (status) {
      case 0:
        result.txt = 'Pending'
        result.color = 'secondary'
        break
      case 1:
        result.txt = 'Picked'
        result.color = 'primary'
        break
      case 3:
        result.txt = 'Routed'
        result.color = 'warning'
        break
      case 7:
        result.txt = 'Dispatched'
        result.color = 'info'
        break
      case 15:
        result.txt = 'Delivered'
        result.color = 'success'
        break
      default:
        break
      }
      return result
    },

    resolveRutaStatusVariant(status) {
      // 0 => Pending, 1 => Picked, 3 => Routed, 7 => Dispatched, 15 Delivered
      const result = {
        txt: 'Unknown',
        color: 'danger',
      }
      switch (status) {
      case 0:
        result.txt = 'Pending'
        result.color = 'secondary'
        break
      case 1:
        result.txt = 'Creada'
        result.color = 'primary'
        break
      case 3:
        result.txt = 'Routed'
        result.color = 'warning'
        break
      case 7:
        result.txt = 'Dispatched'
        result.color = 'info'
        break
      case 15:
        result.txt = 'Delivered'
        result.color = 'success'
        break
      default:
        break
      }
      return result
    },

    resolveRutaPedidos(ruta) {
      const value = this.rutasGroupPedidos[ruta].length
      const result = {
        color: 'secondary',
        value,
      }
      if (value) {
        result.color = 'success'
      }
      return result
    },

    refetchData() {
      // console.log('vale, entendido...')
    },
    exportToExcel() {
      // console.log('Exportando...')
      const list = this.itemsFilterPedidos
      // console.log(list.length)
      const result = []
      // list.forEach(item => {
      for (let index = 0; index < list.length; index += 1) {
        const item = list[index]
        const { productos } = item
        const newItem = JSON.parse(JSON.stringify(item))
        delete newItem.productos
        // console.log('**** Pedido: ', item.id)
        // let line = 0
        // productos.forEach(p => {
        for (let index2 = 0; index2 < productos.length; index2 += 1) {
          const p = productos[index2]
          // line += 1
          newItem.id_line = index2 + 1
          newItem.ref = p.ref
          newItem.uds = p.uds
          result.push(newItem)
          // console.log(`${result.length} - ${newItem.ref} => ${newItem.uds}`)
        }
      }
      // console.log(JSON.stringify(result))
      const headers = Object.keys(result[0])
      // console.log(headers)
      // if (list) return
      import('@/libs/Export2Excel').then(excel => {
        // const list = []
        // this.selected.forEach(item => {
        //   list.push(this.extraeInfo(item))
        // })
        // const list = this.selected
        // const data = this.formatJson(this.headerVal, list)
        const data = this.formatJson(headers, result)
        // const data = result
        excel.export_json_to_excel({
          header: headers,
          data,
          filename: 'export',
          autoWidth: false,
          bookType: 'csv',
        })
        // this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    async calculaRutas() {
      if (!this.rutasSelectedConPedidos.length) return
      console.log('calculando...')
      // Inicializa variables
      this.calculandoRuta = true
      this.rutasOptimizadas = []
      this.rutasSelecOptimizar = []
      // const rutasParaCalcular = []
      this.rutasSelectedConPedidos.forEach(ruta => {
        console.log(ruta)
        const pedidos = this.rutasGroupPedidos[ruta]
        // const waypts = this.items.filter(p)
        const waypts = this.items.filter(p => pedidos.includes(p.id)).map(x => ({
          location: x.cliente_dir_literal,
          stopover: true,
        }))
        this.rutasSelecOptimizar.push({
          ruta,
          pedidos,
          waypts,
        })
      })
      // this.rutasSelecOptimizar = rutasParaCalcular
      for (let index = 0; index < this.rutasSelecOptimizar.length; index += 1) {
        const element = this.rutasSelecOptimizar[index]
        const datos = {
          start: DIRALMACEN,
          end: DIRALMACEN,
          waypts: element.waypts,
        }
        // eslint-disable-next-line no-await-in-loop
        const result = await directions(datos)
        this.rutasOptimizadas.push({
          rutaId: element.ruta,
          rutaCalculada: JSON.parse(JSON.stringify(result)),
        })
      }
      // console.log(this.rutasOptimizadas)
      this.tratarRutaOptimizada()
      this.calculandoRuta = false
    },
    tratarRutaOptimizada() {
      const regex = /\s/ig
      const regex2 = /,\+España/ig
      const regex3 = /\//ig // Elimina el caracter /
      const urlMap = 'https://www.google.com/maps/dir/'
      // const result = []
      this.rutasOptimizadas.forEach(ruta => {
        const { rutaId, rutaCalculada } = ruta
        console.log(rutaId)
        const { legs, overview_path } = rutaCalculada
        console.log(legs)
        const address = legs.map(l => l.start_address.replace(regex3, '.'))
        address.push(DIRALMACEN)
        const link = urlMap + address.join('/').replace(regex, '+').replace(regex2, '')
        const datos = {
          id: rutaId,
          status: 1,
          calculated: {
            address,
            link,
            overview_path,
          },
        }
        this.$store.dispatch('rutas/updateRuta', datos)
        // console.log(datos)
      })
      // console.log(result)
    },
    async preCalculaRutas() {
      console.log('pre-calculando')
      this.calculandoRuta = true
      this.pedidosPreOrdenados = []
      const pedidos = this.itemsFilterPedidos
      this.pedidosOrdenAnterior = pedidos
      const waypts = pedidos.map(p => ({
        location: p.cliente_dir_literal,
        stopover: true,
      }))
      const datos = {
        start: DIRALMACEN,
        end: DIRALMACEN,
        waypts,
      }
      // console.log(datos)
      const result = await directions(datos)
      this.calculandoRuta = false
      this.tratarPreruta(pedidos, JSON.parse(JSON.stringify(result)))
      // console.log(JSON.stringify(result))
      console.log(result.waypoint_order)
      // [0, 3, 6, 2, 4, 5, 7, 1]
      // .then(result => {
      // })
    },
    tratarPreruta(pedidos, preRuta) {
      // const pedidos = this.itemsFilterPedidos
      const orden = preRuta.waypoint_order
      this.overviewPath = preRuta.overview_path
      // console.log(orden)
      const result = []
      let count = 0
      orden.forEach(index => {
        const pedido = pedidos[index]
        pedido.orden = count
        result.push(pedido)
        count += 1
      })
      this.pedidosPreOrdenados = result
      // this.sortBy = 'id'
      // this.isSortDirDesc = true
    },
    newRutaCalculed() {
      console.log('Respuesta desde el mapa: newRutaCalculed')
      // setTimeout(() => {
      //   this.isSortDirDesc = false
      // }, 500)
    },
    test() {
      console.log('test')
      testPdf('Hola')
      // this.sortBy = 'orden'
      // this.isSortDirDesc = false
      // this.tratarRutaOptimizada()
    },
    tratarPrerutaOld() {
      // const pedidos = this.itemsFilterPedidos
      // const orden = preRutaFacke.waypoint_order
      // this.overviewPath = preRutaFacke.overview_path
      // console.log(orden)
      const result = []
      // let count = 0
      // orden.forEach(index => {
      //   const pedido = pedidos[index]
      //   pedido.orden = count
      //   result.push(pedido)
      //   count += 1
      // })
      this.pedidosPreOrdenados = result
      // this.isSortDirDesc = true
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
