import JsPDF from 'jspdf'
// import pedidosMock from '@/@fake-db/data/pedidos_pdf.json'

const TOTAL_ETIQUETAS_PAGINA = 14

const converToHHMM = numero => {
  const secNum = parseInt(numero, 10) // don't forget the second param
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - (hours * 3600)) / 60)

  if (hours < 10) { hours = `0${hours}` }
  if (minutes < 10) { minutes = `0${minutes}` }
  return `${hours}:${minutes}`
}

/**
 * Imprime la etiqueta en la posición indicada
 * @param {*} doc Instancia del documento creado
 * @param {*} posicion Posición de la etiqueta en que comienza (1...14)
 */
function imprimeEtiqueta(doc, posicion = 1, datos) {
  // Marco exterior para dev. Comentar para producción
  doc.rect(0, 0, 210, 297)

  /* ---------------------------------  */
  /* Configuración de la Etiqueta       */
  /* ---------------------------------  */
  const espaH = 5 // Espacio horizontal entre etiquetas
  const espaV = 5 // Espacio vertical entre etiquetas
  const x0 = 2.5 // Coordenada 0 en el eje X
  const y0 = 10.65 // Coordenada 0 en el eje Y
  // const totalFilas = 7
  const totalColumnas = 2
  // recuadro
  const w = 100 // Tamaño recuadro
  const h = 35 // Tamaño recuadro

  /* ---------------------------------  */
  /* Determina las coordenadas 0,0      */
  /* ---------------------------------  */
  // determina la fila
  const fila = Math.ceil(posicion / totalColumnas) - 1
  // Determina la columna: Hay 2 columnas
  const columna = posicion % totalColumnas ? 'izda' : 'dcha'
  // calculo X e Y
  let x = x0
  if (columna === 'dcha') {
    x = x0 + w + espaH
  }

  const y = y0 + (h + espaV) * fila

  /* ---------------------------------  */
  /* Dibuja las líneas y el recuadro    */
  /* ---------------------------------  */
  // Recuadro
  doc.rect(x, y, w, h)

  // Línea Horizontal 1
  let lv = y + 6.4
  doc.line(x, lv, x + w, lv)

  // Línea Vertical 1
  let lh = x + 36.2
  doc.line(lh, y, lh, lv)

  // Línea Horizontal 2
  lv = y + 28.5
  doc.line(x, lv, x + w, lv)

  // Línea Vertical 2
  lh = x + 36.2
  doc.line(lh, lv, lh, y + h)

  // Línea Vertical 3
  lh = x + 77.186
  doc.line(lh, y, lh, y + h)

  /* ---------------------------------  */
  /* Dibuja los Textos                  */
  /* ---------------------------------  */
  // setFont(fontName, fontStyle, fontWeight)
  doc.setFont('Helvetica', 'bold')
  // doc.setFontType('bold')
  doc.setFontSize(12)
  // Línea 1
  // const L1X = x + 2.75
  const L1Y = y + 5
  doc.text('CLI.:', x + 2.75, L1Y, { align: 'left' })
  doc.text('R:', x + 39, L1Y, { align: 'left' })
  doc.text('P:', x + 80, L1Y, { align: 'left' })

  doc.setFont('Helvetica', 'normal')
  doc.text(datos.cliente_id, x + 13, L1Y, { align: 'left' })
  doc.text(datos.ruta, x + 44.5, L1Y, { align: 'left' })
  doc.text(datos.id.substr(-5), x + 85, L1Y, { align: 'left' })

  // Línea 2
  doc.setFont('Helvetica', 'bold')
  doc.text(datos.cliente_nombre, x + 2.75, y + 12, { align: 'left' })
  // Líneas 3 y 4
  doc.setFont('Helvetica', 'normal')
  doc.text(datos.cliente_dir_literal, x + 2.75, y + 19, { align: 'left', maxWidth: 75 })
  // Línea 5
  const y5 = y + 33
  doc.setFont('Helvetica', 'bold')
  doc.text('H:', x + 2.75, y5, { align: 'left' })
  doc.text('F:', x + 46, y5, { align: 'left' })
  doc.text('B:', x + 82, y5, { align: 'left' })

  const hIni = datos.cliente_hora_ini ? converToHHMM(datos.cliente_hora_ini) : ''
  const hFin = datos.cliente_hora_fin ? converToHHMM(datos.cliente_hora_fin) : ''
  const horario = `${hIni || '?'} - ${hFin || '?'}`

  doc.setFont('Helvetica', 'normal')
  doc.text(horario, x + 10, y5, { align: 'left' })

  const fecha = new Date(datos.fecha).toLocaleDateString()
  if (fecha) {
    doc.text(`${fecha}`, x + 52, y5, { align: 'left' })
  }
  const nBultos = datos.numeroBultosTxt // Calculado
  doc.text(nBultos, x + 88, y5, { align: 'left' })
}

export const testPdf = (data, comienzo = 0) => {
  if (!data || !data.length) {
    return
  }
  // console.log(data.length)
  // const fecha = '05/04/21' // data.fecha
  const datos = data
  const doc = new JsPDF('portrait', 'mm') // modo retrato

  // Procesa Datos
  // let countEtiquetas = 0
  const result = []
  datos.forEach(pedido => {
    const numBultos = pedido.numEtiquetas
    // countEtiquetas += numBultos
    for (let index = 0; index < numBultos; index += 1) {
      const pedidoImprime = JSON.parse(JSON.stringify(pedido))
      pedidoImprime.numeroBultosTxt = `${index + 1} / ${numBultos}`
      result.push(pedidoImprime)
    }
  })

  const countEtiquetas = result.length
  // console.log(`Comienza en posición: ${comienzo}`)
  // console.log(`Total etiquetas: ${countEtiquetas}`)

  const numPages = Math.ceil((countEtiquetas + comienzo) / TOTAL_ETIQUETAS_PAGINA)
  // console.log(`Total Páginas: ${numPages}`)
  // Control de páginas
  let etiquetasPrimeraPagina = TOTAL_ETIQUETAS_PAGINA - comienzo
  etiquetasPrimeraPagina = etiquetasPrimeraPagina > countEtiquetas ? countEtiquetas : etiquetasPrimeraPagina
  for (let index = 0; index < numPages; index += 1) {
    // console.log(`----  Página: ${index}`)
    if (index > 0) {
      // Segunda página y siguientes
      doc.addPage()
      const etiquetasIndexDesde = etiquetasPrimeraPagina + TOTAL_ETIQUETAS_PAGINA * (index - 1)
      // const cantidadEtiquetas = TOTAL_ETIQUETAS_PAGINA
      // const etiquetasIndexHasta = (countEtiquetas - etiquetasIndexDesde) > etiquetasPrimeraPagina + TOTAL_ETIQUETAS_PAGINA ? etiquetasPrimeraPagina + TOTAL_ETIQUETAS_PAGINA : countEtiquetas - etiquetasIndexDesde
      for (let e = 0; e < TOTAL_ETIQUETAS_PAGINA; e += 1) {
        const indexEtiqueta = e + etiquetasIndexDesde
        if (indexEtiqueta >= countEtiquetas) break
        const etiqueta = result[indexEtiqueta]
        imprimeEtiqueta(doc, 1 + e, etiqueta)
      }
    } else {
      // Primera página
      for (let e = 0; e < etiquetasPrimeraPagina; e += 1) {
        const etiqueta = result[e]
        imprimeEtiqueta(doc, comienzo + 1 + e, etiqueta)
      }
    }
  }
  // // console.log(doc.getFontList())
  // const dataMock = {
  //   cliente_id: '123456789',
  //   ruta: '1617638015345',
  //   pedido: 'R0697',
  //   cliente_nombre: 'BAR POBLE NOU',
  //   cliente_dir_calle: 'Carrer de Ferrer Bassa',
  //   cliente_dir_numero: '4',
  //   cliente_dir_cp: '08019',
  //   cliente_dir_literal: 'Carrer de Ferrer Bassa, 4, 08019 Barcelona, Spain',
  // }

  // Calcular: numeroBultosTxt

  // imprimeEtiqueta(doc, 1, dataMock)
  doc.save('etiquetas.pdf')
}

export const test2 = data => {
  console.log(data)
  const doc = new JsPDF({
    orientation: 'p',
    unit: 'mm',
    format: [57, 100],
    putOnlyUsedFonts: true,
    floatPrecision: 16,
  })
  console.log(doc)
}
