<template>
  <b-modal
    id="rutasToEtiquetas"
    ref="rutas-to-etiquetas"
    :visible="isEtiquetasSelectionInit"
    title="Imprimir Etiquetas: Incdicar comienzo impresión"
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    hide-footer
    centered
    size="lg"
    @change="(val) => $emit('update:is-etiquetas-selection-init', val)"
  >
    <b-row class="match-height">
      <b-col
        v-for="n in 14"
        :key="n"
        cols="6"
      >
        <b-card
          :title="`${n}`"
          class="`text-center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="saveChanges(n)"
          >
            Seleccionar Posición {{ n }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal,
  BRow, BCol,
  BCard, BButton,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BButton,
    BModal,
    BRow,
    BCol,
    // BCardText,
    // BCardActions,
    BCard,
    BButton,
    // BCardBody,
    // BCardTitle,
    // BCardSubTitle,
    // BLink,
    // BImg,
    // BFormGroup,
    // vSelect,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEtiquetasSelectionInit',
    event: 'update:is-etiquetas-selection-init',
  },
  props: {
    isEtiquetasSelectionInit: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    saveChanges(n) {
      this.$emit('selectedEtiquetasInit', n)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
